<template>
  <div>
    <v-container>
      <!-- 消息条 -->
      <v-snackbar
        :timeout="1500"
        class="mt-4"
        v-model="notiBar.enabled"
        top
        transition="slide-y-transition"
        :color="notiBar.color"
        center
        text
        style="top: 56px"
      >
        {{ notiBar.text }}
      </v-snackbar>
      <!-- 客户端列表区域 -->
      <v-card width="90%" class="mx-auto pa-5 mt-5">
        <v-card-title>
          <v-btn depressed to="/" class="mr-5"
            ><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn
          >
          客户端列表
          <v-spacer></v-spacer>
          <v-btn depressed @click="refresh" class="mx-2"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
          <v-btn depressed @click="createClient" color="primary" class="mx-2"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-card-title>
        <div class="d-flex justify-start flex-wrap my-3">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-if="loading"
          ></v-progress-linear>
          <v-card
            max-width="289px"
            max-height="300px"
            class="ma-4"
            v-for="(item, i) in clientList"
            :key="i"
          >
            <v-list-item two-line>
              <v-list-item-avatar
                tile
                size="30"
                color="grey"
                v-if="item.iconImage != ''"
                ><v-img :src="item.iconImage"></v-img
              ></v-list-item-avatar>
              <v-list-item-avatar
                tile
                size="30"
                color="grey"
                v-if="item.iconImage == ''"
                ><v-img
                  src="https://web-static-1304188470.file.myqcloud.com/img/icon_default_1.png"
                ></v-img
              ></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 mb-1">
                  {{ item.clientName }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.clientID }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-chip
                filter
                v-for="(g, j) in cardGroupShow[i]"
                color="primary"
                :key="j"
                x-small
                class="mx-1"
                >{{ g }}</v-chip
              >
              <v-spacer></v-spacer>
              <v-btn depressed small @click="showEdit(i)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn depressed small @click="doDelete(item.ID)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
      <!-- 客户端列表区域 -->

      <!-- 编辑框 -->
      <v-dialog v-model="edit.dialog" persistent>
        <v-card>
          <v-card-title>{{
            edit.type == "edit" ? "修改客户端信息" : "新建客户端"
          }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="edit.data.clientName"
              label="客户端名"
              outlined
            ></v-text-field>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="edit.data.clientID"
                  label="客户端ID"
                  prepend-inner-icon="mdi-identifier"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="edit.data.clientSecret"
                  label="客户端密钥"
                  prepend-inner-icon="mdi-key"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              v-model="edit.data.iconImage"
              label="图标"
              prepend-inner-icon="mdi-image"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="edit.data.callbackUrl"
              label="回调地址"
              prepend-inner-icon="mdi-link"
              outlined
            ></v-text-field>

            <v-card class="mb-3">
              <v-card-text>
                <h2 class="text-h6">用户群组</h2>
                <v-chip-group v-model="edit.group" multiple>
                  <v-chip
                    filter
                    outlined
                    v-for="(tag, i) in config.group"
                    :key="i"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>

            <v-card class="mb-3">
              <v-card-text>
                <h2 class="text-h6">权限</h2>
                <v-chip-group v-model="edit.scopes" multiple column>
                  <v-chip
                    filter
                    outlined
                    v-for="(tag, i) in config.scopes"
                    :key="i"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="submitEdit">
              {{ edit.type == "edit" ? "提交修改" : "创建" }}
            </v-btn>
            <v-btn color="red darken-1" text @click="edit.dialog = false">
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 编辑框 -->

      <!-- 提示框 -->
      <v-dialog v-model="alert.alert" max-width="450px">
        <v-card>
          <v-card-title>{{ alert.alert_type }}</v-card-title>
          <v-card-text>
            {{ alert.alert_text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="alert.alert = !alert.alert"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 删除提示框 -->
      <v-dialog v-model="deleteData.dialog" max-width="450px">
        <v-card>
          <v-card-title>确认删除</v-card-title>
          <v-card-text> 请再次确认？ </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="doDelete">删除</v-btn>
            <v-btn color="blue darken-1" text @click="deleteData.dialog = false"
              >取消</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "adminClients",
  data: function () {
    return {
      clientList: [],
      loading: false,
      alert: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      notiBar: {
        enabled: false,
        color: "",
        text: "",
      },
      config: {
        scopes: null,
        group: null,
      },
      deleteData: {
        dialog: false,
        id: 0,
      },
      
      edit: {
        data: {},
        type: "edit",
        dialog: false,
        groupMap: null,
        scopeMap: null,
        group: null, //与编辑框绑定
        scopes: null, //编辑框
      },
      cardGroupShow: [],
    };
  },
  mounted: function () {
    console.log(this.edit);
    this.initData();
    this.initConfig();
  },
  methods: {
    initData: function () {
      this.loading = true;
      //清除数据
      this.cardGroupShow = new Array();

      //获取数据
      this.axios
        .get("/api/admin/listClient")
        .then((res) => {
          if (res.data.status === 0) {
            this.clientList = res.data.data.list;
            //处理cardGroupShow
            this.clientList.forEach((client) => {
              if (client.allowGroup.length > 3) {
                var b = [...client.allowGroup].slice(0, 2);
                b[2] = "...";
                this.cardGroupShow.push(b);
              } else {
                this.cardGroupShow.push(client.allowGroup);
              }
            });
          } else {
            this.error(this.parseError(res));
          }
          this.loading = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    initConfig: function () {
      this.axios
        .get("/api/admin/getClientConfig")
        .then((res) => {
          if (res.data.status === 0) {
            this.config.group = res.data.data.group.list;
            this.config.scopes = res.data.data.scopes.list;

            this.edit.groupMap = res.data.data.group.map;
            this.edit.scopeMap = res.data.data.scopes.map;
          } else {
            this.error(this.parseError(res));
          }
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    submitEdit: function () {
      var path = "";
      if (this.edit.type == "edit") {
        path = "editClient";
      } else {
        path = "createClient";
      }

      //处理group 和 scopes
      this.edit.data.allowGroup = [];
      this.edit.data.scopes = [];
      this.edit.group.forEach((element) => {
        this.edit.data.allowGroup.push(this.config.group[element]);
      });
      this.edit.scopes.forEach((element) => {
        this.edit.data.scopes.push(this.config.scopes[element]);
      });

      //发送
      this.axios({
        method: "post",
        url: "/api/admin/" + path,
        data: this.edit.data,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.edit.dialog = false;
            this.successBar("操作成功");
            this.initData();
          } else {
            this.error(this.parseError(res));
          }
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },

    refresh: function () {
      this.initConfig();
      this.initData();
      this.successBar("刷新成功");
    },
    doDelete: function (id) {
      if (this.deleteData.dialog == false) {
        this.deleteData.id = id;
        this.deleteData.dialog = true;
        return;
      }

      //发送
      this.axios({
        method: "post",
        url: "/api/admin/deleteClient",
        data: {
          id: this.deleteData.id,
        },
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.deleteData.dialog = false;
            this.initData();
            this.successBar("删除成功");
          } else {
            this.error(this.parseError(res));
          }
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    createClient: function () {
      this.edit.type = "new";
      this.edit.group = [];
      this.edit.scopes = [];
      this.edit.data = new Object();
      this.edit.data.callbackUrl = "https://account.roy233.com/api/ssoCallback";
      this.edit.data.clientSecret = this.randomString(16);

      this.edit.dialog = true;
    },
    showEdit: function (id) {
      this.edit.data = this.clientList[id];
      this.edit.group = [];
      this.edit.scopes = [];
      this.edit.type = "edit";

      this.edit.data.allowGroup.forEach((group) => {
        this.edit.group.push(this.edit.groupMap[group]);
      });
      this.edit.data.scopes.forEach((scope) => {
        this.edit.scopes.push(this.edit.scopeMap[scope]);
      });
      this.edit.dialog = true;
    },
    randomString: function (e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    success: function (text) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = text;
      this.alert.alert_type = "成功";
    },
    error: function (err) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = err;
      this.alert.alert_type = "错误";
    },
    successBar: function (text) {
      this.notiBar.enabled = !this.notiBar.enabled
      this.notiBar.text = text;
      this.notiBar.color = "success";
    },
    errorBar: function (err) {
      this.notiBar.enabled = !this.notiBar.enabled
      this.notiBar.text = err;
      this.notiBar.color = "error";
    },
  },
};
</script>

<style></style>
